<template>
  <div class="productFromWrap">
    <div class="rkInfoWrap">
      <div class="titleWrap">
        <!-- <img class="img" src="../../../assets/rkxx.png" alt="" /> -->
        <div class="title">入库信息</div>
      </div>
      <div class="contentWrap">
        <template v-if="listInStock.length">
          <div
            class="rowContent"
            v-for="(item, index) in listInStock"
            :key="index"
          >
            <div class="rowWrap">
              <div class="left">入库时间</div>
              <div class="right">{{ item.inStockTime }}</div>
            </div>
            <div class="rowWrap">
              <div class="left">入库数量</div>
              <div class="right">{{ item.inStockNum }} {{ item.unitName }}</div>
            </div>
          </div>
        </template>
        <van-empty v-else description="暂无数据"></van-empty>
      </div>
    </div>
    <div class="qjInfoWrap">
      <div class="titleWrap">
        <!-- <img class="img" src="../../../assets/qjxx.png" alt="" /> -->
        <div class="title">清酒液信息</div>
      </div>
      <div class="contentWrap">
        <template v-if="packBrightBrew.length">
          <div
            class="rowContent"
            v-for="(item, index) in packBrightBrew"
            :key="index"
          >
            <div class="rowWrap">
              <div class="left">物料名称</div>
              <div class="right">{{ item.materialName }}</div>
            </div>
            <div class="rowWrap">
              <div class="left">生产批号</div>
              <div class="right">{{ item.batchNo }}</div>
            </div>
            <div class="rowWrap">
              <div class="left">检验结果</div>
              <div class="right">
                <span class="hege" v-if="item.qmsResult == '合格'">合格</span>
                <span class="buhege" v-if="item.qmsResult == '不合格'"
                  >不合格</span
                >
              </div>
            </div>
          </div>
        </template>
        <van-empty v-else description="暂无数据"></van-empty>
      </div>
    </div>
    <div class="fjInfoWrap">
      <div class="titleWrap">
        <!-- <img class="img" src="../../../assets/qjxx.png" alt="" /> -->
        <div class="title">发酵液信息</div>
      </div>
      <div class="contentWrap">
        <template v-if="brightBrew.length">
          <div
            class="rowContent"
            v-for="(item, index) in brightBrew"
            :key="index"
          >
            <div class="rowWrap">
              <div class="left">物料名称</div>
              <div class="right">{{ item.materialName }}</div>
            </div>
            <div class="rowWrap">
              <div class="left">生产批号</div>
              <div class="right">{{ item.batchNo }}</div>
            </div>
            <div class="rowWrap">
              <div class="left">检验结果</div>
              <div class="right">
                <span class="hege" v-if="item.qmsResult == '合格'">合格</span>
                <span class="buhege" v-if="item.qmsResult == '不合格'"
                  >不合格</span
                >
              </div>
            </div>
          </div>
        </template>
        <van-empty v-else description="暂无数据"></van-empty>
      </div>
    </div>
    <div class="thInfoWrap">
      <div class="titleWrap">
        <!-- <img class="img" src="../../../assets/fjxx.png" alt="" /> -->
        <div class="title">糖化液信息</div>
      </div>
      <div class="contentWrap">
        <template v-if="fermentList.length">
          <div
            class="rowContent"
            v-for="(item, index) in fermentList"
            :key="index"
          >
            <div class="rowWrap">
              <div class="left">物料名称</div>
              <div class="right">{{ item.materialName }}</div>
            </div>
            <div class="rowWrap">
              <div class="left">生产批号</div>
              <div class="right">{{ item.batchNo }}</div>
            </div>
            <div class="rowWrap">
              <div class="left">检验结果</div>
              <div class="right">
                <span class="hege" v-if="item.qmsResult == '合格'">合格</span>
                <span class="buhege" v-if="item.qmsResult == '不合格'"
                  >不合格</span
                >
              </div>
            </div>
          </div>
        </template>
        <van-empty v-else description="暂无数据"></van-empty>
      </div>
    </div>
    <div class="yclInfoWrap">
      <div class="titleWrap">
        <!-- <img class="img" src="../../../assets/thxx.png" alt="" /> -->
        <div class="title">原材料信息</div>
      </div>
      <div class="contentWrap">
        <template v-if="mashList.length">
          <div
            class="rowContent"
            v-for="(item, index) in mashList"
            :key="index"
          >
            <div class="rowWrap">
              <div class="left">物料名称</div>
              <div class="right">{{ item.materialName }}</div>
            </div>
            <div class="rowWrap">
              <div class="left">生产批号</div>
              <div class="right">{{ item.batchNo }}</div>
            </div>
            <div class="rowWrap">
              <div class="left">检验结果</div>
              <div class="right">
                <span class="hege" v-if="item.qmsResult == '合格'">合格</span>
                <span class="buhege" v-if="item.qmsResult == '不合格'"
                  >不合格</span
                >
              </div>
            </div>
          </div>
        </template>
        <van-empty v-else description="暂无数据"></van-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { queryH5 } from "@/api/allRequest";
export default {
  data() {
    return {
      listInStock: [], //入库信息
      packBrightBrew: [], //清酒液信息
      brightBrew: [], //发酵液信息
      fermentList: [], //糖化液信息
      mashList: [], //原材料信息
    };
  },

  methods: {
    //初始化数据
    init() {
      var data = {
        ...JSON.parse(localStorage.getItem("params")),
      };
      if (!JSON.parse(localStorage.getItem("params")).prodDate) {
        data.prodDate = localStorage.getItem("prodDate");
      }
      if (!JSON.parse(localStorage.getItem("params")).batchNo) {
        data.batchNo = localStorage.getItem("batchNo");
      }
      queryH5(data).then((res) => {
        if (res.flag == 1) {
          if (res.data) {
            this.listInStock = res.data.listInStock ? res.data.listInStock : [];
            this.brightBrew = res.data.brightBrew ? res.data.brightBrew : [];
            this.fermentList = res.data.fermentList ? res.data.fermentList : [];
            this.mashList = res.data.mashList ? res.data.mashList : [];
            this.packBrightBrew = res.data.packBrightBrew
              ? res.data.packBrightBrew
              : [];
          } else {
            this.listInStock = [];
            this.brightBrew = [];
            this.fermentList = [];
            this.mashList = [];
            this.packBrightBrew = [];
          }
        } else {
          this.$toast.fail(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.productFromWrap {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 12px;
  box-sizing: border-box;
  .rkInfoWrap,
  .qjInfoWrap,
  .fjInfoWrap,
  .thInfoWrap,
  .yclInfoWrap {
    margin-bottom: 16px;
    .titleWrap {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .title {
        color: #303330;
        font-size: 16px;
        font-weight: bolder;
        margin-left: 4px;
      }
      .img {
        width: 25px;
        height: 25px;
      }
    }
    .contentWrap {
      background: #ffffff;
      border-radius: 4px;
      padding: 12px;
      box-sizing: border-box;
      .rowContent {
        border-bottom: 1px solid #ebeef5;
        margin-bottom: 8px;
        .rowWrap {
          display: flex;
          width: 100%;
          margin-bottom: 8px;
          .left {
            font-size: 14px;
            color: #909399;
            width: 120px;
          }
          .right {
            color: #303133;
            font-size: 14px;
            flex: 1;
            text-align: right;
            .hege {
              background: #01b48e;
              border-radius: 4px;
              font-size: 12px;
              color: #fff;
              padding: 3px 6px;
            }
            .buhege {
              background: #e84f4d;
              border-radius: 4px;
              font-size: 12px;
              color: #fff;
              padding: 3px 6px;
            }
          }
        }
      }
      .rowContent:last-child {
        margin-bottom: 0;
        border-bottom: none;
        .rowWrap:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .yclInfoWrap {
    margin-bottom: 0;
  }
}
.productFromWrap::-webkit-scrollbar {
  display: none;
}
</style>
