<template>
  <div class="qcReportWrap">
    <template v-for="(item, index) in dataList">
      <template v-if="item.fileUrl.indexOf('[') != -1">
        <div style="margin-bottom: 12px" :key="index">
          <div
            class="contentWrap"
            v-for="(items, index_) in JSON.parse(item.fileUrl)"
            :key="index_"
          >
            <div class="leftWrap">
              <div class="left" v-if="!isAssetTypeAnImage(items.fileUrl)">
                <img class="img" src="../../../assets/rcbg.png" alt="" />
              </div>
              <div class="left" v-else>
                <img
                  @click="previewImg(items.fileUrl)"
                  class="img"
                  :src="items.fileUrl"
                  alt=""
                />
              </div>
              <div class="right">
                <div class="top">{{ item.enumValue }}</div>
                <div class="btm">{{ items.fileName }}</div>
              </div>
            </div>
            <div class="rightWrap">
              <a
                :href="items.fileUrl"
                target="_blank"
                v-if="!isAssetTypeAnImage(items.fileUrl)"
              >
                <img
                  class="downloadImg"
                  src="../../../assets/download.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="contentWrap" :key="index">
          <div class="leftWrap">
            <div class="left">
              <img class="img" src="../../../assets/rcbg.png" alt="" />
            </div>
            <div class="right">
              <div class="top">{{ item.enumValue }}</div>
              <div class="btm">{{ item.fileUrl }}</div>
            </div>
          </div>
          <div class="rightWrap">
            <a :href="item.fileUrl" target="_blank">
              <img
                class="downloadImg"
                src="../../../assets/download.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { getBaseMaterialQmsUrl } from "@/api/allRequest";
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      show: false,
      dataList: [],
    };
  },
  methods: {
    //预览图片
    previewImg(fileUrl) {
      ImagePreview([fileUrl]);
    },
    //判断图片类型
    isAssetTypeAnImage(filePath) {
      //获取最后一个.的位置
      var index = filePath.lastIndexOf(".");
      //获取后缀
      var ext = filePath.substr(index + 1);
      return (
        [
          "png",
          "jpg",
          "jpeg",
          "bmp",
          "gif",
          "webp",
          "psd",
          "svg",
          "tiff",
        ].indexOf(ext.toLowerCase()) !== -1
      );
    },
    //初始化数据
    init() {
      var data = {
        ...JSON.parse(localStorage.getItem("params")),
      };
      if (!JSON.parse(localStorage.getItem("params")).prodDate) {
        data.prodDate = localStorage.getItem("prodDate");
      }
      if (!JSON.parse(localStorage.getItem("params")).batchNo) {
        data.batchNo = localStorage.getItem("batchNo");
      }
      getBaseMaterialQmsUrl(data).then((res) => {
        if (res.flag == 1) {
          this.dataList = res.data ? res.data : {};
        } else {
          this.$toast.fail(res.message);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.qcReportWrap {
  width: 100%;
  height: 100%;
  padding: 12px;
  overflow: auto;
  box-sizing: border-box;
  .contentWrap {
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 12px;
    box-sizing: border-box;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leftWrap {
      display: flex;
      align-items: center;
      flex: 1;
      .left {
        .img {
          width: 48px;
          height: 48px;
          margin-right: 12px;
        }
      }
      .right {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-width: 230px;
        overflow: hidden;
        .top {
          color: #303133;
          font-size: 16px;
          font-weight: bolder;
        }
        .btm {
          color: #909399;
          font-size: 14px;
          margin-top: 5px;
          text-overflow: ellipsis;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .rightWrap {
      width: 30px;
      display: flex;
      justify-content: flex-end;
      .downloadImg {
        width: 22px;
        height: 22px;
      }
    }
  }
  .contentWrap:last-child {
    margin-bottom: 0;
  }
}
.qcReportWrap::-webkit-scrollbar {
  display: none;
}
</style>