<template>
  <div class="productInfoWrap">
    <div class="topContentWrap">
      <div class="titleWrap">产品信息</div>
      <div class="content">
        <div class="rowWrap">
          <div class="left">品名</div>
          <div class="right">{{ productInfoData.materialName }}</div>
        </div>
        <div class="rowWrap">
          <div class="left">规格</div>
          <div class="right">
            {{ productInfoData.spec ? productInfoData.spec : "-" }}
          </div>
        </div>
        <div class="rowWrap">
          <div class="left">配料表</div>
          <div class="right">{{ productInfoData.recipeContent }}</div>
        </div>
        <div class="rowWrap">
          <div class="left">酒精度</div>
          <div class="right">{{ productInfoData.alcoholContent }} %vol</div>
        </div>
        <div class="rowWrap">
          <div class="left">原麦汁浓度</div>
          <div class="right">{{ productInfoData.originalGravity }} °P</div>
        </div>
        <div class="rowWrap">
          <div class="left">生产日期</div>
          <div class="right">{{ productInfoData.prodDate }}</div>
        </div>
        <div class="rowWrap">
          <div class="left">质保期</div>
          <div class="right" v-if="productInfoData.qualityPeriod">
            {{ productInfoData.qualityPeriod }}天
          </div>
        </div>
        <div class="rowWrap">
          <div class="left">产品标准号</div>
          <div class="right">{{ productInfoData.productCriterionNo }}</div>
        </div>
        <div class="rowWrap">
          <div class="left">生产许可证编号</div>
          <div class="right">{{ productInfoData.productionNo }}</div>
        </div>
        <div class="rowWrap">
          <div class="left">批次号</div>
          <div class="right">{{ productInfoData.batchNo }}</div>
        </div>
        <div class="rowWrap">
          <div class="left">生产商</div>
          <div class="right">{{ productInfoData.companyName }}</div>
        </div>
        <div class="rowWrap">
          <div class="left">生产地址</div>
          <div class="right">{{ productInfoData.factoryAddress }}</div>
        </div>
        <div class="rowWrap">
          <div class="left">联系方式</div>
          <div class="right">{{ productInfoData.factoryContact }}</div>
        </div>
        <div class="rowWrap">
          <div class="left">注意</div>
          <div class="right">{{ productInfoData.noteContent }}</div>
        </div>
      </div>
    </div>
    <div class="btmContentWrap" v-if="productInfoData.recallReason">
      <div class="titleWrap">召回信息</div>
      <div class="content">
        <template v-if="productInfoData.recallReason">
          <div class="rowWrap">
            <div class="left">召回原因</div>
            <div class="right">{{ productInfoData.recallReason }}</div>
          </div>
          <div class="rowWrap">
            <div class="left">召回说明</div>
            <div class="right">{{ productInfoData.recallNote }}</div>
          </div>
        </template>
        <van-empty v-else description="无召回信息" />
      </div>
    </div>
  </div>
</template>

<script>
import { getBaseMaterialProduct } from "@/api/allRequest";
export default {
  data() {
    return {
      productInfoData: {}, //产品信息
    };
  },
  methods: {
    //初始化数据
    init() {
      var data = {
        ...JSON.parse(localStorage.getItem("params")),
      };
      if (!JSON.parse(localStorage.getItem("params")).prodDate) {
        data.prodDate = localStorage.getItem("prodDate");
      }
      if (!JSON.parse(localStorage.getItem("params")).batchNo) {
        data.batchNo = localStorage.getItem("batchNo");
      }
      getBaseMaterialProduct(data).then((res) => {
        if (res.flag == 1) {
          this.productInfoData = res.data ? res.data : {};
        } else {
          this.$toast.fail(res.message);
        }
      });
    },
    //点击返回
    handleBack() {
      history.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.productInfoWrap {
  width: 100%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  overflow: auto;
  .topContentWrap {
    width: 100%;
    margin-bottom: 12px;
    background: #ffffff;
    border-radius: 4px;
    padding-top: 16px;
    box-sizing: border-box;
    .titleWrap {
      display: flex;
      align-items: center;
      color: #303133;
      font-size: 16px;
      font-weight: bolder;
      margin-bottom: 12px;
    }
    .titleWrap:before {
      width: 3px;
      height: 16px;
      background: #ffe100;
      content: "";
      display: block;
      margin-right: 12px;
    }
    .content {
      width: 100%;
      padding: 12px;
      box-sizing: border-box;
      .rowWrap {
        display: flex;
        width: 100%;
        margin-bottom: 8px;
        .left {
          font-size: 14px;
          color: #909399;
          width: 120px;
        }
        .right {
          color: #303133;
          font-size: 14px;
          flex: 1;
          text-align: right;
        }
      }
      .rowWrap:last-child {
        margin-bottom: 0;
      }
    }
  }
  .btmContentWrap {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 16px;
    box-sizing: border-box;

    .titleWrap {
      display: flex;
      align-items: center;
      color: #303133;
      font-size: 16px;
      font-weight: bolder;
      margin-bottom: 12px;
    }
    .titleWrap:before {
      width: 3px;
      height: 16px;
      background: #ffe100;
      content: "";
      display: block;
      margin-right: 12px;
    }
    .content {
      width: 100%;
      padding: 12px;
      box-sizing: border-box;
      .rowWrap {
        display: flex;
        width: 100%;
        margin-bottom: 8px;
        .left {
          font-size: 14px;
          color: #909399;
          width: 120px;
        }
        .right {
          color: #303133;
          font-size: 14px;
          flex: 1;
          text-align: right;
        }
      }
      .rowWrap:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.productInfoWrap::-webkit-scrollbar {
  display: none;
}
</style>
