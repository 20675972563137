<template>
  <div class="tabPageWrap">
    <div class="topNavWrap">
      <van-nav-bar
        title="智能追溯系统 (QTS)"
        left-arrow
        :border="false"
        @click-left="handleBack"
      />
    </div>
    <div class="contentWrap">
      <template v-if="activeIndex == 0">
        <ProductInfo ref="productInfo"></ProductInfo>
      </template>
      <template v-if="activeIndex == 1">
        <ProductFrom ref="productFrom"></ProductFrom>
      </template>
      <template v-if="activeIndex == 2">
        <QcReport ref="qcReport"></QcReport>
      </template>
    </div>
    <div class="btmNavWrap">
      <van-tabbar v-model="activeIndex" @change="handleTabChange">
        <van-tabbar-item>
          <span>产品信息</span>
          <template #icon="props">
            <img :src="props.active ? icons1.active : icons1.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item>
          <span>生产追溯</span>
          <template #icon="props">
            <img :src="props.active ? icons2.active : icons2.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item>
          <span>质检报告</span>
          <template #icon="props">
            <img :src="props.active ? icons3.active : icons3.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item>
          <span>商城</span>
          <template #icon="props">
            <img :src="props.active ? icons4.active : icons4.inactive" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { getAccessToken, getGenerateScheme } from "@/api/allRequest";
import ProductInfo from "./productInfo";
import ProductFrom from "./productFrom";
import QcReport from "./qcReport";
import axios from "axios";
export default {
  data() {
    return {
      activeIndex: 0,
      icons1: {
        active: require("../../assets/cp.png"),
        inactive: require("../../assets/cpgray.png"),
      },
      icons2: {
        active: require("../../assets/zs.png"),
        inactive: require("../../assets/zsgray.png"),
      },
      icons3: {
        active: require("../../assets/zj.png"),
        inactive: require("../../assets/zjgray.png"),
      },
      icons4: {
        active: require("../../assets/sc.png"),
        inactive: require("../../assets/scgray.png"),
      },
    };
  },
  components: {
    ProductInfo,
    ProductFrom,
    QcReport,
  },
  mounted() {
    this.activeIndex = 0;
    this.handleTabChange(this.activeIndex);
  },
  methods: {
    //点击返回
    handleBack() {
      history.back();
    },
    //切换页签
    handleTabChange(val) {
      if (val == 0) {
        this.$nextTick(() => {
          this.$refs.productInfo.init();
        });
      } else if (val == 1) {
        this.$nextTick(() => {
          this.$refs.productFrom.init();
        });
      } else if (val == 2) {
        this.$nextTick(() => {
          this.$refs.qcReport.init();
        });
      } else if (val == 3) {
        this.goMiniProgram();
      }
    },
    //跳转小程序
    goMiniProgram() {
      // var data = {
      //   appid: "wx5638329f07ffbc27",
      //   secret: "e8f8020e5d1315689ae61b0074ec1462",
      // };
      getAccessToken().then((res) => {
        if (res.flag == 1) {
          getGenerateScheme({ access_token: res.data }).then((resp) => {
            if (resp.flag == 1) {
              location.href = resp.data;
            } else {
              this.$toast.fail(resp.message);
            }
          });
        } else {
          this.$toast.fail(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tabPageWrap {
  background: #f2f5ff;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  ::v-deep .van-tabbar-item--active {
    color: #303313;
  }
  ::v-deep .van-nav-bar__left {
    .van-icon-arrow-left {
      color: #303313;
    }
  }
  .topNavWrap {
    height: 46px;
    width: 100%;
  }
  .contentWrap {
    width: 100%;
    height: calc(100% - 96px);
  }
  .btmNavWrap {
    height: 50px;
  }
}
</style>
